import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleContinue = () => {
    navigate("/"); // Điều hướng tới trang chủ hoặc trang giao dịch tiếp theo
  };
  const orderId = searchParams.get("orderId");

  useEffect(() => {
    console.log(orderId);
  }, [orderId]);

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "50px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h1 style={{ color: "#28a745" }}>Thanh toán thành công!</h1>
      <p style={{ fontSize: "18px", color: "#555" }}>
        Cảm ơn bạn đã sử dụng dịch vụ của chúng tôi. Giao dịch của bạn đã được
        thực hiện thành công.
      </p>
      <p style={{ fontSize: "16px", color: "#777" }}>
        Bạn có thể tiếp tục giao dịch hoặc quay lại trang chủ để khám phá thêm.
      </p>
      <button
        onClick={handleContinue}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          fontSize: "16px",
          color: "#fff",
          backgroundColor: "#007bff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Tiếp tục giao dịch
      </button>
    </div>
  );
};

export default PaymentSuccess;
