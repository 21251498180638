export const LIST = "LIST_COIN";
export const LIST_SUCCESS = "LIST_COIN_SUCCESS";
export const LIST_FAILED = "LIST_COIN_FAILED";

export const CREATE_ORDER = "CREATE_ORDER_COIN";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_COIN_SUCCESS";
export const CREATE_ORDER_FAILED = "CREATE_ORDER_COIN_FAILED";

export const HISTORIES_ORDER = "HISTORIES_ORDER_COIN";
export const HISTORIES_ORDER_SUCCESS = "HISTORIES_ORDER_COIN_SUCCESS";
export const HISTORIES_ORDER_FAILED = "HISTORIES_ORDER_COIN_FAILED";

export const METHOD_PAYMENT = "METHOD_PAYMENT";
export const METHOD_PAYMENT_SUCCESS = "METHOD_PAYMENT_SUCCESS";
export const METHOD_PAYMENT_FAILED = "METHOD_PAYMENT_FAILED";

export const DETAIL_ORDER = "DETAIL_ORDER";
export const DETAIL_ORDER_SUCCESS = "DETAIL_ORDER_SUCCESS";
export const DETAIL_ORDER_FAILED = "DETAIL_ORDER_FAILED";

export const WITHDRAW_ORDER = "WITHDRAW_ORDER";
export const WITHDRAW_ORDER_SUCCESS = "WITHDRAW_ORDER_SUCCESS";
export const WITHDRAW_ORDER_FAILED = "WITHDRAW_ORDER_FAILED";

export const HISTORIES_WITHDRAW = "HISTORIES_WITHDRAW";
export const HISTORIES_WITHDRAW_SUCCESS = "HISTORIES_WITHDRAW_SUCCESS";
export const HISTORIES_WITHDRAW_FAILED = "HISTORIES_WITHDRAW_FAILED";

export const RESET_DATA = "RESET_DATA_COIN";
