export const ROUTES = {
  HOME_PAGE: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  MAINTANCE: "/maintance",
  SEARCH_TRANSACTION: "/search_transaction",
  MENU: "/menu",
  NEWS: "/menu/news",
  NEWS_DETAIL: "/menu/news/:id",
  QUESTION_ANSWER: "/menu/question_answer",
  GUIRE: "/menu/guire",
  GUIRE_DETAIL: "/menu/guire/:id",
  CONTACT: "/menu/contact",
  HOLD_COIN: "/menu/holding_coin",
  WITHDRAW_COIN: "/menu/withdraw_coin",
  PROFILE: "/profile",
  INFO: "/profile/info",
  BANK_ACCOUNT: "/profile/bank_account",
  WALLET: "/profile/wallet",
  HISTORIES: "/profile/histories",
  HISTORIES_WITHDRAW: "/profile/histories_withdraw",
  CHANGE_PASSWORD: "/profile/change_password",
  PAYMENT_SUCCESS: "/success",
  PAYMENT_CANCEL: "/cancel",
};
