export const ADD = "ADD_CUSTOMER";
export const ADD_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_FAILED = "ADD_CUSTOMER_FAILED";

export const EDIT = "EDIT_CUSTOMER";
export const EDIT_SUCCESS = "EDIT_CUSTOMER_SUCCESS";
export const EDIT_FAILED = "EDIT_CUSTOMER_FAILED";

export const EDIT_BANK = "EDIT_BANK_CUSTOMER";
export const EDIT_BANK_SUCCESS = "EDIT_BANK_CUSTOMER_SUCCESS";
export const EDIT_BANK_FAILED = "EDIT_BANK_CUSTOMER_FAILED";

export const EDIT_WALLET = "EDIT_WALLET_CUSTOMER";
export const EDIT_WALLET_SUCCESS = "EDIT_WALLET_CUSTOMER_SUCCESS";
export const EDIT_WALLET_FAILED = "EDIT_WALLET_CUSTOMER_FAILED";

export const SEND_CONTACT = "SEND_CONTACT_CUSTOMER";
export const SEND_CONTACT_SUCCESS = "SEND_CONTACT_CUSTOMER_SUCCESS";
export const SEND_CONTACT_FAILED = "SEND_CONTACT_CUSTOMER_FAILED";

export const RESET_DATA = "RESET_DATA_CUSTOMER";
