import FormNoneInfo from "components/common/FormNoneInfo";
import { ROUTES } from "constants/routerWeb";
import _capitalize from "lodash/capitalize";
import { useEffect, useState } from "react";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actionClearError, actionLogin } from "store/Login/action";
import BackgroundImage from "../../assets/images/bg.jpg";
import Logo from "../../assets/images/logo.jpg";
import "./index.scss";

function Maintance() {
  // state store
  const loginState = useSelector((state) => state.loginReducer);
  // action store
  const dispatch = useDispatch();
  const onLogin = (body) => dispatch(actionLogin(body, true));
  const onClearError = () => dispatch(actionClearError());
  const {
    loginStatus: { isLoading, isSuccess, isFailure },
    data,
  } = loginState;

  const { user, error: errorLogin } = data;

  // state local
  const navigate = useNavigate();
  const [isForget, setForget] = useState(false);
  const [formdata, setData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    if (isSuccess) {
      if (user.id && !user?.image) {
        navigate(ROUTES.INFO);
      } else {
        navigate(ROUTES.HOME_PAGE);
      }
    }
  }, [navigate, isSuccess]);

  // function local
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
    setError((prevError) => ({ ...prevError, [name]: "" }));
    if (isFailure) setError((prevError) => ({ ...prevError, password: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const tmpKey = Object.keys(formdata);
    let validates = true;
    tmpKey.forEach((key) => {
      if (formdata[key] === "") {
        setError((prevError) => ({
          ...prevError,
          [key]: `${_capitalize(key)} required`,
        }));
        validates = false;
      }
    });
    if (validates) {
      // dispatch
      onLogin({ email: formdata.username, password: formdata.password });
    }
  };

  return (
    <div
      className="sign-in__wrapper"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {/* Header */}
      <img
        className="mx-auto d-block mb-2 rounded-circle"
        src={Logo}
        width={70}
        height="auto"
        alt="logo"
      />
      {/* Form */}

      <FormNoneInfo
        visible={true}
        onClose={() => setForget(false)}
        title="Thông báo"
        icon={
          <i
            className="fas fa-info-circle text-warning"
            style={{ fontSize: "90px" }}
          ></i>
        }
        content={
          <div className="mt-3">
            Trang web hiện đang bảo trì, Bạn vui lòng liên hệ{" "}
            <div>
              <Link className="text-primary" to="https://zalo.me/0332986587">
                Zalo Admin
              </Link>{" "}
              để thực hiện giao dịch
            </div>
          </div>
        }
      />
    </div>
  );
}

export default Maintance;
