import axiosInstance from "helper/axiosConfig";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const PaymentCancelled = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const handleRetry = () => {
    navigate("/"); // Điều hướng tới trang chủ hoặc trang giao dịch tiếp theo
  };

  useEffect(() => {
    if (orderId) {
      axiosInstance.put("api/order-coins/cancel/" + orderId, {}).then((res) => {
        console.log(res);
      });
    }
  }, [orderId]);

  return (
    <div style={styles.container}>
      <div style={styles.messageBox}>
        <h1 style={styles.title}>Thanh toán đã bị hủy</h1>
        <p style={styles.message}>
          Giao dịch của bạn đã bị hủy. Nếu đây là một sự nhầm lẫn, vui lòng thử
          lại hoặc liên hệ với chúng tôi để được hỗ trợ.
        </p>
        <button style={styles.retryButton} onClick={handleRetry}>
          Quay lại trang chủ
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f8f9fa",
  },
  messageBox: {
    textAlign: "center",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "24px",
    color: "#dc3545",
  },
  message: {
    fontSize: "16px",
    color: "#6c757d",
    marginBottom: "20px",
  },
  retryButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default PaymentCancelled;
