import BtnSocials from "components/common/BtnSocials";
import Header from "components/header/HeaderUser";
import { Outlet, useNavigate } from "react-router-dom";
import "./layout.scss";
import { useEffect, useState } from "react";
import axiosInstance from "helper/axiosConfig";
import Maintance from "pages/Maintance";
function DefaultLayout() {
  const navigate = useNavigate();
  const [ismaintance, setIsmaintance] = useState(false);
  useEffect(() => {
    axiosInstance.get("/api/admin/maintance").then((res) => {
      if (res.data.data) {
        setIsmaintance(true);
      }
    });
  }, []);

  return ismaintance ? (
    <Maintance />
  ) : (
    <>
      <Header />
      <Outlet />
      <BtnSocials />
    </>
  );
}

export default DefaultLayout;
