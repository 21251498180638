import React from 'react';

function NotFoundPage(props) {
    return (
        <div>
            <h1>404-Page Not Found</h1>
        </div>
    );
}

export default NotFoundPage;